.add-varient {
    border: none;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    background: #45b4ff;
    color: #FFF;

}

.save-btnnnnnn {
    border: none;
    font-size: 16px;
    border-radius: 5px;
    background: green;
    color: #FFF;
}

.save-btnnnnnn:hover {
    border: none;
    font-size: 16px;
    border-radius: 5px;
    background: green;
    color: #FFF;
}