/* .StepForm-recruiter #msform {
  text-align: center;
  position: relative;
  margin: 1% 8% 0% 8%;
}

.StepForm-recruiter #msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

.StepForm-recruiter .wizard-form .form-card {
  text-align: left;
  margin-bottom: 5%;
  margin-top: 3%;
}

.StepForm-recruiter #msform fieldset:not(:first-of-type) {
  display: none;
}

.StepForm-recruiter button,
input,
optgroup,
select,
textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #707070;
  border-radius: 5px;

  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;

  font-size: 16px;
}

.StepForm-recruiter #msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #1967d3;
  outline-width: 0;
}

.StepForm-recruiter #msform .action-button {
  width: 100px;
  background: #1967d3;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 0px 0px 10px 5px;
  float: right;
}

.StepForm-recruiter #msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

.StepForm-recruiter #msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

.StepForm-recruiter .card {
  z-index: 0;
  border: none;
  position: relative;
}

.StepForm-recruiter .fs-title {
  font-size: 27px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
}
.StepForm-recruiter span.blue-line {
  background-color: #1967d3;
  width: 10px;
  height: 25px;
  position: absolute;
  margin-top: 1%;
}

.StepForm-recruiter .purple-text {
  color: #673ab7;
  font-weight: normal;
}

.StepForm-recruiter .steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

.StepForm-recruiter .fit-image {
  width: 100%;
  object-fit: cover;
}

.StepForm-recruiter .Step3_input {
  width: auto !important;
  margin-bottom: 0 !important;
}

.StepForm-recruiter .step1input {
  width: auto !important;
  margin-bottom: 0 !important;
}

.common-footer .login-row {
  font-size: 12px;
  margin-left: 7%;
}
.common-footer .login-row .login-btn {
  color: #1967d3;
  font-size: 12px;
  text-decoration: none;
}
.common-footer .login-row .signup-btn {
  color: #1967d3;
  font-size: 12px;
  text-decoration: none;
}
.common-footer .check-row {
  font-size: 14px;
  margin-bottom: 2%;
}
.common-footer .check-row .term-btn {
  color: #1967d3;
  font-size: 14px;
  text-decoration: none;
}
.common-footer .check-row .form-check-input:checked {
  background-color: black;
  border-color: black;
}
.common-footer .check-row input.form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  padding: 0px;
}


.StepForm-recruiter .stepForm_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #1967d342;
  border-radius: 10px;
}

.StepForm-recruiter .image-holder {
  position: relative;
}

.StepForm-recruiter .signup-image {
  width: 100%;
  height: 525px;
  object-fit: cover;
}

.StepForm-recruiter .overlay-holder {
  position: absolute;
  top: 25%;
  left: 17%;
}
.StepForm-recruiter .image-holder .overlay-holder .middle-content h1 {
  font-weight: 500;
  font-size: 34px;
  color: #ffffff;
  text-align: left;
}
.StepForm-recruiter .image-holder .overlay-holder .middle-content p {
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
  text-align: left;
}
.StepForm-recruiter .image-holder .overlay-holder .middle-content p.create {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}

.StepForm-recruiter .form-card .otpContainer input {
  border-bottom: 1px solid #707070;
  border-right: none;
  border-top: none;

  border-left: none;
  padding: 0px 10px;
  font-size: 14px;
  text-align: center;
  margin: 0 16px 0 0;
  width: 20px;
  border-radius: 0px;
  height: 11px;
}
.StepForm-recruiter .form-card .otpContainer {
  margin-top: 2%;
}
.StepForm-recruiter .form-card .otp {
  font-size: 12px;
}
.StepForm-recruiter .form-card .react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px 0px 0px 5px;
  width: 100%;
  outline: none;
  padding: 7px 50px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
  margin-top: 0px;
  border-right: none;
}

.StepForm-recruiter .form-card .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1967d3;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  text-decoration: underline;
  border-left: none;
}
.StepForm-recruiter .form-card label.fieldlabels {
  font-size: 18px;
  font-weight: 500;
}

.StepForm-recruiter.stepForm_card .card-body .back-btn {
  color: black;
  text-decoration: underline;
  font-size: 14px;
}
.StepForm-recruiter .stepForm_card .card-body .back-btn:hover {
  color: #1967d3;
  text-decoration: underline;
}


.StepForm-recruiter .progress-bar-container .step-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.StepForm-recruiter .progress-bar-container .step-container {
  position: relative;
}

.StepForm-recruiter .line-right {
  width: 55px;
  height: 5px;
  background: #dfdfdf;
  position: absolute;
  top: 50%;
  left: 100%;
}
.StepForm-recruiter .progress-bar-container .line-left {
  width: 50px;
  height: 5px;
  background: #dfdfdf;
  position: absolute;
  top: 50%;
  right: 101%;
}

.StepForm-recruiter .line-left.completed {
  background-color: #1967d3;
}

.StepForm-recruiter .line-right.completed {
  background-color: #1967d3;
}

.StepForm-recruiter .progress-bar-container .circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #1967d3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.StepForm-recruiter .progress-bar-container .circle.completed {
  background-color: #1967d3;
  border-color: #1967d3;
  color: white;
}
.StepForm-recruiter .header-content .signup-text {
  font-size: 20px;
  margin-top: 2%;
  margin-left: -7%;
  font-weight: 500;
}
.StepForm-recruiter .header-content .info-text {
  font-size: 30px;
  font-weight: bold;
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .common-footer .login-row {
    font-size: 12px;
  }
  .common-footer .login-row .login-btn {
    font-size: 12px;
  }
  .common-footer .login-row .signup-btn {
    font-size: 12px;
  }
  .common-footer .check-row {
    font-size: 14px;
  }
  .common-footer .check-row .term-btn {
    font-size: 14px;
  }
  .StepForm-recruiter .progress-bar-container .line-left {
    width: 28px;
}
.StepForm-recruiter .form-card .react-tel-input .form-control {
  width: 117%;
}
.StepForm-recruiter .form-card .input-group-text {
  margin-left: 11%;
}
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .common-footer .login-row {
    font-size: 10px;
  }
  .common-footer .login-row .login-btn {
    font-size: 10px;
  }
  .common-footer .login-row .signup-btn {
    font-size: 10px;
  }
  .common-footer .check-row {
    font-size: 12px;
  }
  .common-footer .check-row .term-btn {
    font-size: 12px;
  }
  .StepForm-recruiter .progress-bar-container .line-left {
    width: 28px;
}
.StepForm-recruiter .form-card .react-tel-input .form-control {
  width: 117%;
}
.StepForm-recruiter .form-card .input-group-text {
  margin-left: 11%;
}
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .common-footer .login-row {
    font-size: 12px;
  }
  .common-footer .login-row .login-btn {
    font-size: 12px;
  }
  .common-footer .login-row .signup-btn {
    font-size: 12px;
  }
  .common-footer .check-row {
    font-size: 14px;
  }
  .common-footer .check-row .term-btn {
    font-size: 14px;
  }
} */

.single-variant-tabsshd .nav-link.active {
  background-color: #fff;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000 !important;
}

.single-variant-tabsshd .nav-tabs .nav-link:hover {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 0 !important;
}

.single-variant-tabsshd .nav-link {
  font-weight: normal;
  font-size: 14px;
  color: #656565;
  /* margin-bottom: 10px; */
  width: max-content;
}

.single-variant-tabsshd .nav-pills {
  border-bottom: 1px solid #ccc;
}

.single-variant-tabsshd .proppdp {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.single-variant-tabsshd .proppdp span {
  font-weight: 500;
  font-size: 10px;
}

.single-variant-tabsshd .tab-content-data .uploadbox {
  background: #f5f5f5;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
}

.single-variant-tabsshd .tab-content-data .uploadbox .upd-card {
  position: relative;
  width: 125px;
  height: 125px;
  text-align: center;
}

.single-variant-tabsshd .tab-content-data .uploadbox .upd-card .slide-img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 5px;
}

.single-variant-tabsshd .tab-content-data .uploadbox .upd-card .dltt-slide {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
}

.single-variant-tabsshd .tab-content-data .uploadbox .upd-card .dltt-slide-img {
  width: 15px;
  position: absolute;
  top: -4px;
  left: 53px;
}

.single-variant-tabsshd .thumbnail-txt {
  font-size: 14px;
}

.single-variant-tabsshd .button-uploadd {
  border: none;
  border-radius: 5px;
  background: #dfdfdf;
  font-size: 14px;
  padding: 6px 16px;
}

.single-variant-tabsshd .multiinner-table td {
  font-size: 14px;
}

.single-variant-tabsshd .tab-content-data .uploadbox .swiper {
  padding: 6px 0px !important;
}

.single-variant-tabsshd .tab-content-data .uploadbox .swiper-pagination {
  display: none;
}

.single-variant-tabsshd .tab-content-data .produuyct-discri .tttt {
  font-weight: 500;
  font-size: 12px;
  color: #262626;
  margin-bottom: 5px;
}

.single-variant-tabsshd .tab-content-data .produuyct-discri .testtt {
  font-weight: normal;
  font-size: 10px;
  color: #262626;
  margin-bottom: 5px;
}

.multi-scroll-box .tab-content-data .uploadbox .upd-card .slide-img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 5px;
  position: absolute;
}

.multi-scroll-box .nav-pills {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 16px;
}

.multi-scroll-box .nav-link.active {
  background-color: #fff;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid #1c42a9;
  border-radius: 0px;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #000 !important;
}

.multi-scroll-box .nav-tabs .nav-link {
  text-wrap: nowrap !important;
}

.multi-scroll-box .nav-tabs::-webkit-scrollbar {
  height: 5px;
}

.multi-scroll-box .nav-tabs::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.single-variant-tabsshd .form-check-label {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.single-variant-tabsshd .form-label {
  font-size: 14px;
}

.single-variant-tabsshd .multiinner-table td {
  font-size: 14px;
}

.single-varient-sec .table-responsive th {
  font-size: 14px;
  font-weight: 600;
}

.single-varient-sec .table-responsive td {
  font-size: 14px;
  font-weight: 500;
}

.single-varient-sec .sec-div .green-add {
  font-size: 14px;
  color: green;
  cursor: pointer;
}

.single-varient-sec .sec-div .red-add {
  font-size: 14px;
  color: #ef0000;
  cursor: pointer;
}

.single-variant-tabsshd .form-label {
  font-weight: 500;
}

.single-variant-tabsshd .form-check-input:checked {
  background-color: #656565;
  border-color: #818181;
}

.single-varient-sec .sec-div .add-new-attribute-class {
  font-size: 14px;
  color: #1c42a9;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
  text-align: end;
  border: none;
  background: transparent;
}

.single-varient-sec .table-responsive .add-product-btn {
  font-size: 14px;
  color: green;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
  text-align: end;
  border: none;
  background: transparent;
}

.single-varient-sec .table-responsive .remove-product-btn {
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin-right: 27px;
  cursor: pointer;
  text-align: end;
  border: none;
  background: transparent;
}

.single-varient-sec .varient-firsttt {
  border-right: 1px solid #ced4da;
  padding-right: 15px;
}

.single-varient-sec .form-control::placeholder {
  font-size: 14px;
}

.single-varient-sec .form-control {
  font-size: 14px;
  width: 100%;
}
