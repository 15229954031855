@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", "sans-serif" !important;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.modal-header {
  border-bottom: 0;
}

.sidebarHeadClose .sidebar .top_section h1 {
  font-size: 18px;
  margin-bottom: 0;
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: 82%;
  z-index: 9999999;
}



.sidebarHeadopen .sidebar .top_section h1 {
  margin-bottom: 0;
}

/* .input-mandatory {
  --bs-gutter-x: -0.5rem !important;
  width: 95%;
} */

.sidebar-x-scroll {
  --bs-gutter-x: 0px !important;
}

.sidebarHeadopen {
  width: 330px;
  transition: 0.3s ease;
}

.sidebarHeadClose {
}

.dashboradopen {
  width: 100%;
}

.allRoutesMain {
  height: 100vh;
  overflow-y: auto;
  background: rgb(242 242 242);
}

.title {
  font-size: 24px;
}

.sidebarHeadClose .accordion-button::after {
  background: transparent;
}

.sidebarHeadClose .accordion-button {
  padding-left: 40px;
}

.sidebarHeadClose .sidebar .logo {
  width: 79px;
}

.form-check-input:checked {
  background-color: #45b4ff;
  border-color: #45b4ff;
}

.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  text-wrap: wrap;
}

table tbody td img {
  object-fit: contain;
}

/* For Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
