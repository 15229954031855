/* Main container */
.pending-table_holder {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pending-table_holder .pending-table {
  padding: 20px;
  background-color: #f8f9fa;
}

/* Quick Filters Section */
/* Block: quick-filters */
.pending-table_holder .quick-filters {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label and tabs */
  font-family: Arial, sans-serif;
  padding: 10px 10px;
  margin-bottom: 30px;
}

/* Element: quick-filters__label */
.pending-table_holder .quick-filters__label {
  font-weight: bold;
  font-size: 14px;
  color: #555; /* Slightly muted color for the label */
}

/* Element: quick-filters__tabs */

/* Element: quick-filters__tab */
.pending-table_holder .quick-filters__tab {
  background: #f5f5f5; /* Light gray background for tabs */
  border: 1px solid #ddd; /* Light border for tabs */
  color: #007185; /* Text color for tabs */
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.pending-table_holder .quick-filters__tab:active {
  background: #007185 !important; /* Active tab background color */
  color: #fff !important; /* White text for active tab */
  border-color: #007185 !important;
}

/* Modifier: quick-filters__tab--active */
.pending-table_holder .quick-filters__tab--active {
  background: #007185 !important; /* Active tab background color */
  color: #fff; /* White text for active tab */
  border-color: #007185 !important;
}

/* Focus State */
.pending-table_holder .quick-filters__tab:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 2px rgba(0, 113, 133, 0.3); /* Custom focus outline */
}

/* Table Section */
.pending-table_holder .order-table {
  width: 100%;
  background-color: white;
  font-family: Arial, sans-serif;
}

.pending-table_holder thead {
  background-color: #f1f1f1;
}

.pending-table_holder th {
  padding: 12px;
  text-align: left;
  font-weight: bold;
}

.pending-table_holder td {
  padding: 12px;
}

/* Order details column */
.pending-table_holder .order-details {
  /* display: flex; */
  /* flex-direction: column; */
  width: 200px;
}

.pending-table_holder .order-id {
  font-weight: bold;
  margin-bottom: 5px;
}

.pending-table_holder .order-info span {
  font-size: 14px;
  color: #6c757d;
}

/* Product name column */
.pending-table_holder .product-name {
  max-width: 250px;
}

/* Order Status */

.pending-table_holder .status-holder {
  margin-bottom: 0px;
}

.pending-table_holder .Unshipped {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background: #e74a3b;
  padding: 3px 10px;
  text-wrap-mode: nowrap;
  margin-bottom: 10px;
  width: 100%;
}

.pending-table_holder .shipped {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background: green;
  padding: 3px 10px;
  text-wrap-mode: nowrap;
  margin-bottom: 10px;
  width: 100%;
}

.pending-table_holder .text-highlight {
  color: #008296 !important;
}

.pending-table_holder .text-purple-holder {
  color: purple !important;
}
/* Action column */
.pending-table_holder .action-btn {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 50px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 15px;
  padding: 3px 20px;
  width: 100%;
  height: unset !important;
  text-wrap-mode: nowrap;
}

.pending-table_holder .action-btn.active {
  background-color: #ffc107;
  background: #ffc107;
  border: 1px solid #ffc107;
  color: #000;
}

.pending-table_holder .action-btn:active {
  background-color: #ffc107 !important;
  background: #ffc107 !important;
  border: 1px solid #ffc107 !important;
  color: #000 !important;
}

.pending-table_holder .action-btn:hover {
  background-color: #ffc107;
  background: #ffc107;
  border: 1px solid #ffc107;
  color: #000;
}

/* Image styling */
.pending-table_holder .product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

/* Action column buttons */
.pending-table_holder .action-col button {
  margin-right: 10px;
}

/* Filters Section Styles */
.pending-table_holder .filters-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.pending-table_holder .filters-section__label {
  font-size: 15px;
  font-weight: 500;
  color: #ccc;
  text-wrap-mode: nowrap;
  margin-right: 11px;
  display: flex;
  align-items: center;
}

.pending-table_holder .filters-section__label .hide_btn {
  background: #000;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #000;
  margin-right: 5px;
  padding: 5px 15px;
  height: unset;
}

.pending-table_holder .filters-section__label .hide_btn:hover {
  background: #ccc;
  color: #000;
}

.pending-table_holder .filters-section__label .hide_btn:active {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.pending-table_holder .filters-section__label h6 {
  margin-bottom: 0px;
}

.pending-table_holder .filters-section__label span {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.pending-table_holder .filters-section__controls {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-flow: wrap;
}

.pending-table_holder .filters-section__dropdown {
  min-width: 200px;
}

.pending-table_holder .filters-section__btn {
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
}

.pending-table_holder .filters-section__btn:hover {
  background: #007185 !important; /* Active tab background color */
  color: #fff; /* White text for active tab */
  border-color: #007185 !important;
}

.pending-table_holder .filters-section__btn:active {
  background: #007185 !important; /* Active tab background color */
  color: #fff; /* White text for active tab */
  border-color: #007185 !important;
}

.pending-table_holder .filters-section__btn.active {
  background: #007185 !important; /* Active tab background color */
  color: #fff; /* White text for active tab */
  border-color: #007185 !important;
}

/* Quick Filters Styles */
.pending-table_holder .quick-filters {
  margin-bottom: 20px;
}

.pending-table_holder .quick-filters__label {
  font-size: 16px;
  font-weight: 600;
  text-wrap-mode: nowrap;
}

.pending-table_holder .quick-filters__tabs {
  display: flex;
  gap: 10px;
  /* margin-top: 10px; */
  flex-wrap: wrap;
}

.pending-table_holder .quick-filters__tab {
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  height: unset;
  /* width: 100%; */
  text-wrap-mode: nowrap;
}

.pending-table_holder .quick-filters__tab {
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 14px;
  height: unset;
  /* width: 100%; */
  text-wrap-mode: nowrap;
}
/* Table Styles */
.pending-table_holder .pending-table {
  margin-top: 20px;
}

.pending-table_holder .order-table {
  width: 100%;
  border-collapse: collapse;
}

.pending-table_holder .order-table th,
.pending-table_holder .order-table td {
  text-align: left;
  padding: 10px;
}

.pending-table_holder .order-table th {
  background-color: #f8f8f8;
  text-wrap-mode: nowrap;
}

.pending-table_holder .order-id {
  font-weight: bold;
}

.pending-table_holder .text-highlight {
  color: #007bff;
}

.pending-table_holder .product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.pending-table_holder .filter-tabs-container {
  margin: 20px;
}

.pending-table_holder .tabs {
  display: flex;
  border-bottom: 2px solid #ccc;
}

.pending-table_holder .waiting-tab {
  padding: 10px 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #00718594;
  transition: background-color 0.3s;
  padding: 2px 30px;
  margin: 0px 5px;
}

.pending-table_holder .waiting-tab span {
  color: #e74a3b;
}

.pending-table_holder .waiting-tab.active {
  border-bottom: 2px solid #007185;
  color: #007185;
}

.pending-table_holder .waiting-tab:hover {
  border-bottom: 2px solid #007185;
  color: #007185;
}

@media (max-width: 768px) {
  .pending-table_holder .order-table th,
  .pending-table_holder .order-table td {
    font-size: 14px;
  }
}
